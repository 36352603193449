import type {MetaFunction} from '@remix-run/node';

import {generateMetaTags} from '~/features/seo/utils/generateMetaTags';

export const meta: MetaFunction = () => {
  return generateMetaTags({
    title: 'Analyse Blockchains',
    description:
      'Analyse various blockchains to determine the decentralization score and the health of the validators. The Observatory measuring system takes into account key details such as the country, ISP, governance, and more.',
    keywords: [
      'blockchain',
      'crypto',
      'validator',
      'staking',
      'crypto validator',
      'best crypto validator',
      'crypto validator node',
      'crypto validator reward',
      'crypto validator list',
      'crypto validator nodes',
      'cosmos observatory',
      'cosmos zones',
      'zones',
      'defi',
      'smartcontract',
      'liquid staking',
      'blockchain validator',
      'validator overview',
      'analyse validators',
      'validator tools',
      'validator research',
      'block validation in blockchain',
      'best liquid staking',
    ],
  });
};
