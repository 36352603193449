import {useRouteError} from '@remix-run/react';
import {captureRemixErrorBoundaryError} from '@sentry/remix';
import {IconMoodSadDizzy} from '@tabler/icons-react';

import {ErrorPage} from '~/components/ErrorPage';

export function ErrorBoundary() {
  const error = useRouteError();

  captureRemixErrorBoundaryError(error);

  return (
    <ErrorPage
      errorCode={<IconMoodSadDizzy size={200} />}
      title="Unexpected error occured"
      description="Blockchains are unavailable due to unexpected error. We are already aware of this issue and working on fixing it."
      redirectTo=""
    />
  );
}
