import classes from './ZoneCard.module.css';

import { Card, Group, Image, Stack, Text } from '@mantine/core';
import type { CardProps } from '@mantine/core';
import cx from 'clsx';

import { ScoreLine } from '~/components/ScoreLine';
import { SensorIcon } from '~/components/SensorIcon';
import { SyncTextBadge } from '~/components/SyncBadge';
import { Link } from '~/features/links';
import { NumberFormat } from '~/utils/NumberFormat';

import type { LandingZone } from '../model/types';

interface ZoneCardProps extends Omit<CardProps, 'children'> {
  zone: LandingZone;
}

export function ZoneCard({ zone, className, ...others }: ZoneCardProps) {
  return (
    <Card
      component={Link}
      to={zone.link}
      withBorder
      className={cx(classes.root, className)}
      title={zone.name}
      {...others}>

      <SensorIcon sensorEnabled={zone.sensorEnabled} className={classes.sensor} />
      <Group wrap="nowrap" w="100%">
        <Image src={zone.logo} w={50} fit="contain" className={classes.flexNone} />
        <Stack gap={5} className={classes.flexGrow}>
          <div>
            <Text fw={700} lineClamp={1} className={classes.zoneTitle}>
              {zone.name}
            </Text>
            <Text
              component="p"
              size="xs"
              mt={0}
              mb={5}
              className={classes.zoneDescription}
              lts={2}
              tt="uppercase"
              fw={500}
              lineClamp={1}>

              {zone.description}
            </Text>
          </div>
          <div>
            {typeof zone.score === 'number' && zone.sensorEnabled &&
            <ScoreLine withLabel score={zone.score} tooltip={`Overall score: ${NumberFormat.score(zone.score)}`} />}

            <SyncTextBadge size="xs" syncedAt={zone.syncedAt} />
          </div>
        </Stack>
      </Group>
    </Card>);

}